import { ContentBase, ModuleBase } from '~/types'

interface Content extends Omit<ContentBase, 'title'> {
  modules: ModuleBase[][]
}

import module5Image from '~/images/module-5.png'

const content: Content = {
  modules: [
    [
      {
        // MODULE 1
        title: 'Grooves',
        number: 1,
        lessons: [
          'Aula 1 – Grooves – Apresentação e aplicações',
          'Aula 2 – O primeiro Groove conduzindo em semínimas',
          'Aula 3 – O primeiro Groove conduzindo em colcheias',
          'Aula 4 – 3 pontos muito importantes no estudo dos Grooves',
          'Aula 5 – Grooves em colcheias dobrando bumbos e transcrições',
          'Aula 6 - Grooves em colcheias dobrando caixas e transcrições',
          'Aula 7 - Grooves em colcheias com bumbos no meio do tempo e transcrições',
          'Aula 8 - Grooves em colcheias em dois compassos e transcrições',
          'Aula 9 - Grooves em colcheias tocando bumbo e caixa juntos parte 01',
          'Aula 10 - Grooves em colcheias tocando bumbo e caixa juntos parte 02 e transcrições',
          'Aula 11 - Grooves em colcheias sincopados parte 01',
          'Aula 12 - Grooves em colcheias sincopados parte 02 e transcrições',
          'Aula 13 – Desenvolvimento de Rimshot e Notas Fantasmas',
          'Aula 14 – Grooves com a caixa fora do chimbau parte 01',
          'Aula 15 – Grooves com a caixa fora do chimbau parte 02',
          'Aula 16 – Grooves com a caixa fora do chimbau parte 03',
          'Aula 17 – Grooves com caixa fora do chimbau sincopados e transcrições',
          'Aula 18 – Desenvolvimento do bumbo fora do chimbau',
          'Aula 19 – Grooves com o bumbo fora do chimbau parte 01',
          'Aula 20 – Grooves com o bumbo fora do chimbau parte 02',
          'Aula 21 – Grooves com o bumbo fora do chimbau parte 03 e transcrições',
          'Aula 22 – Grooves com o bumbo e a caixa fora do chimbau parte 01',
          'Aula 23 – Grooves com o bumbo e a caixa fora do chimbau parte 02 e transcrições',
          'Aula 24 – Grooves com o bumbo e a caixa fora do chimbau sincopados parte 01',
          'Aula 25 – Grooves com o bumbo e a caixa fora do chimbau sincopados parte 02 e transcrições',
          'Aula 26 – 15 conduções para aplicar nos Grooves',
        ],
      },
      {
        // MODULE 2
        title: 'Viradas',
        number: 2,

        lessons: [
          'Aula 1 – Conceito do curso',
          'Aula 2 – Como estudar',
          'Aula 3 – O que são viradas e fills?',
          'Aula 4 – Tamanho de viradas',
          'Aula 5 – Viradas começando no meio dos tempos',
          'Aula 6 – Viradas distribuindo um tempo do Rulo Simples',
          'Aula 7 – Viradas distribuindo meio tempo do Rulo Simples',
          'Aula 8 – Viradas de um tempo na caixa com novas figuras musicais',
          'Aula 9 a 14 – Viradas de dois tempos na caixa',
          'Aula 10 – Viradas de dois tempos na caixa 02',
          'Aula 11 – Viradas de dois tempos na caixa 03',
          'Aula 12 – Viradas de dois tempos na caixa 04',
          'Aula 13 – Viradas de dois tempos na caixa 05',
          'Aula 14 – Viradas de dois tempos na caixa 06',
          'Aula 15 – Viradas de quatro tempos na caixa',
          'Aula 16 – Transcrições de viradas na caixa',
          'Aula 17 – Viradas de um tempo nos tambores 01',
          'Aula 18 – Viradas de um tempo nos tambores 02',
          'Aula 19 – Viradas de dois tempos nos tambores 01',
          'Aula 20 – Viradas de dois tempos nos tambores 02',
          'Aula 19 – Viradas de quatro tempos nos tambores 01',
          'Aula 20 – Viradas de quatro tempos nos tambores 02',
          'Aula 21 – Transcrições de viradas nos tambores',
          'Aula 22 e 23 – Viradas com bumbo 01',
          'Aula 24 – Transcrições de viradas com bumbo',
          'Aula 25 – Viradas com acentuações 01',
          'Aula 26 – Viradas com acentuações 02',
          'Aula 27 – Transcrições de viradas com acentuações',
          'Aula 28 – Viradas com Flams',
          'Aula 29 – Viradas com Flams 01',
          'Aula 30 – Viradas com Flams 02',
        ],
      },
      {
        // MODULE 3
        title: 'Técnica de Mãos',
        number: 3,
        submodules: [
          {
            title: 'Os 7 Rudimentos Essenciais',
            lessons: [
              'Aula 1 – Conceito e breve história dos Rudimentos',
              'Aula 2 – Rulo Simples',
              'Aula 3 – Rulo de pressão',
              'Aula 4 – Rulo Longo (Papa Mama)',
              'Aula 5 – O rebote do Rulo Longo',
              'Aula 6 – Rulo de 5 toques',
              'Aula 7 – Paradiddle',
              'Aula 8 – Flam',
              'Aula 9 – Drag',
            ],
          },
          {
            title: 'Distribuição e Aplicação de Rudimentos',
            lessons: [
              'Aula 1 – Conceito das distribuições e aplicações de rudimentos na bateria',
              'Aula 2 - Criatividade, improviso e percepção - Conceitos e exercícios de percepção',
              'Aula 3 – Conceito de Pergunta e Resposta',
              'Aula 4 - Criando Pergunta e Resposta',
              'Aula 5 - Criando um tema a partir de uma rítmica',
              'Aula 6 – Distribuindo um tempo com o Rulo Simples',
              'Aula 7 – Distribuindo meio tempo com o Rulo Simples',
              'Aula 8 – Distribuindo uma nota do Rulo Simples',
              'Aula 9 – Distribuindo duas notas do Rulo Simples',
              'Aula 10 – Distribuindo o Rulo Simples – Combinações 01',
              'Aula 11 – Distribuindo o Rulo Simples – Combinações 02',
              'Aula 11 – Aplicações do Rulo de 3 com o Rulo Simples',
              'Aula 12 – Aplicações do Rulo de 5 com o Rulo Simples',
              'Aula 13 – Aplicações do Rulo de 9 com o Rulo Simples',
              'Aula 14 – Aplicações do Rulo de 4 toques 01',
              'Aula 15 – Aplicações do Rulo de 4 toques 02',
              'Aula 16 – Aplicações do Rulo de 4 toques 03',
              'Aula 17 – Aplicações do Rulo de 7 toques',
              'Aula 18 – Aplicações do Rulo de 5 toques',
              'Aula 19 – Aplicações do Rulo de 6 toques',
              'Aula 20 – Aplicações do Rulo de 7 toques (com Rulo Longo)',
              'Aula 21 – Aplicações do Rulo de 9 toques',
              'Aula 22 – Aplicações do Paradiddle',
              'Aula 23 – Aplicações do Flam',
            ],
          },
          {
            title: 'Desenvolvimento de Técnica, Velocidade e Resistência',
            lessons: [
              'Aula 1 – Aquecimento / Alongamento',
              'Aula 2 – 3 exercícios para desenvolvimento dos pulsos',
              'Aula 3 – Distribuindo o Rulo Simples nos tambores em semicolcheias',
              'Aula 4 – Distribuindo o Rulo Simples nos tambores em fusas e semicolcheias',
              'Aula 5 – Rulo Simples em semicolcheias e sextinas',
              'Aula 6 – Resistência e velocidade: Mãos em uníssono',
              'Aula 7 – Resistência e velocidade aumentando o número de notas',
              'Aula 8 – Resistência e velocidade em 25 compassos',
            ],
          },
          {
            title: 'Os 40 Rudimentos',
            lessons: [
              'Aula 1 – Rulo Simples',
              'Aula 2 – Rulo de 4 toques',
              'Aula 3 – Rulo de 7 toques',
              'Aula 4 – Rulo de pressão',
              'Aula 5 – Triple stroke roll',
              'Aula 6 – Rulo Longo',
              'Aula 7 – Rulo de 5 toques',
              'Aula 8 – Rulo de 6 toques',
              'Aula 9 – Rulo de 7 toques (com Rulo Longo)',
              'Aula 10 – Rulo de 9 toques',
              'Aula 11 – Rulo de 10 toques',
              'Aula 12 – Rulo de 11 toques',
              'Aula 13 – Rulo de 13 toques',
              'Aula 14 – Rulo de 15 toques',
              'Aula 15 – Rulo de 17 toques',
              'Aula 16 – Single Paradiddle',
              'Aula 17 – Double Paradiddle',
              'Aula 18 – Triple Paradiddle',
              'Aula 19 – Single Paradiddle-diddle',
              'Aula 20 – Flam',
              'Aula 21 – Flam accent',
              'Aula 22 – Flam tap',
              'Aula 23 – Flamacue',
              'Aula 25 – Flam Paradiddle',
              'Aula 26 – Single flammed mil',
              'Aula 27 – Pataflafla',
              'Aula 28 – Swiss army triplet',
              'Aula 29 – Inverted Flam tap',
              'Aula 30 – Flam Drag',
              'Aula 31 – Drag',
              'Aula 32 – Single Drag tap',
              'Aula 33 – Double Drag tap',
              'Aula 34 – Lesson 25',
              'Aula 35 – Single Dragadiddle',
              'Aula 36 – Drag Paradiddle #1',
              'Aula 37 – Drag Paradiddle #2',
              'Aula 38 – Single Ratamacue',
              'Aula 39 – Double Ratamacue',
              'Aula 40 – Triple Ratamacue',
            ],
          },
          {
            title: 'Acentuações',
            lessons: [
              'Aula 1 – Acentuando uma nota do Rulo Simples',
              'Aula 2 – Acentuando duas notas do Rulo Simples',
              'Aula 3 – Combinações de acentuações 01',
              'Aula 4 – Combinações de acentuações 02',
            ],
          },
        ],
      },
    ],
    [
      {
        // MODULE 4
        title: 'Técnica de Pés',
        number: 4,
        video: {
          videoSrcURL: 'https://player.vimeo.com/video/413096952',
          videoTitle: 'Grooves - Apresentação',
        },
        lessons: [
          'Aula 1 – Exercício para precisão e controle 01',
          'Aula 2 – Exercícios para precisão e controle 02 a 05',
          'Aula 3 – Exercício para resistência e velocidade',
          'Aula 4 – Distribuindo um toque de bumbo no Rulo Simples',
          'Aula 5 – Combinações de caixa e bumbo com um toque',
          'Aula 6 – Mudando a condução dos exercícios com um toque',
          'Aula 7 – Aplicando as distribuições com pratos',
          'Aula 8 – Aplicando as combinações em viradas',
          'Aula 9 - Distribuindo dois toques de bumbo no Rulo Simples',
          'Aula 10 - Combinações de caixa e bumbo com dois toques',
          'Aula 11 - Mudando a condução dos exercícios com dois toques',
          'Aula 12 – Aplicando as distribuições de dois toques com pratos',
          'Aula 13 – Aplicando as combinações de dois toques em viradas',
          'Aula 14 – Precisão e controle do toque duplo',
          'Aula 15 – Toque duplo de bumbo rápido – Tipos de execução',
          'Aula 16 – Toque duplo de bumbo rápido – Exercícios em fusas',
          'Aula 17 – Toque duplo de bumbo rápido – Exercícios em fusas e sextinas',
          'Aula 18 – Grooves com toque duplo de bumbo rápido',
        ],
      },
      {
        // MODULE 5
        title: 'Samba',
        number: 5,
        video: {
          videoSrcURL: 'https://player.vimeo.com/video/484043564',
          videoTitle:
            'Aula - Aplicação e distribuição dos rudimentos na baterias',
        },
        image: module5Image,
        lessons: [
          'Aula 1 – O ostinato do samba no chimbau e bumbo / Aplicações rítmicas com o aro',
          'Aula 2 – Melodias de um compasso',
          'Aula 3 – Melodias de dois compassos',
          'Aula 4 – Texto 1',
          'Aula 5 – Texto 2',
          'Aula 6 – Ostinato no prato de condução adicionando o pé esquerdo / Aplicações rítmicas com o aro / Outras possibilidades de condução',
          'Aula 7 - Melodias de um compasso com o novo ostinato',
          'Aula 8 - Melodias de dois compassos com o novo ostinato',
          'Aula 9 – Textos com o novo ostinato',
          'Aula 10 – Samba rápido – Ostinato de 3 notas',
          'Aula 11 - Melodias de um compasso com o ostinato de 3 notas',
          'Aula 12 - Melodias de dois compassos com o ostinato de 3 notas',
          'Aula 13 - Textos com o ostinato de 3 notas',
        ],
      },
      {
        // MODULE 6
        title: 'Grooves de Shuffle',
        number: 6,
        lessons: [
          'Aula 1 – O feeling do Shuffle, divisão rítmica, escrita e aplicações',
          'Aula 2 – Grooves dobrando bumbos',
          'Aula 3 – Grooves dobrando caixas',
          'Aula 4 – Grooves com bumbo no meio dos tempos',
          'Aula 5 – Grooves com notas fantasmas 01',
          'Aula 6 – Grooves com notas fantasmas 02',
          'Aula 7 – O Shuffle “Trenzinho” e com condução de Jazz',
          'Aula 8 – Half time Shuffle',
          'Aula 9 – Transcrições e aplicações de músicas',
        ],
      },
      {
        // MODULE 7
        title: 'Reggae',
        number: 7,
        video: {
          videoSrcURL: 'https://player.vimeo.com/video/468658100',
          videoTitle: 'Fórmula de compasso - Compasso composto',
        },
        lessons: [
          'Aula 1 – Contagem dos tempos, escrita, One drop e Four drop',
          'Aula 2 – Grooves de um compasso',
          'Aula 3 – Grooves de dois compassos',
          'Aula 4 - Grooves mudando a condução com notas no aro',
          'Aula 5 – Acentuações na condução',
          'Aula 6 – Mesclando todos os conceitos',
          'Aula 7 – Pop/Fusion Reggae',
          'Aula 8 - Transcrições e aplicações de músicas',
        ],
      },
      {
        // MODULE 8
        title: 'Montando a bateria / Posicionamento',
        number: 8,
        lessons: [
          'Aula 1 – Como montar a bateria',
          'Aula 2 – Como segurar as baquetas',
          'Aula 3 – Posição das baquetas no instrumento',
          'Aula 4 – Como posicionar e tocar os pés',
        ],
      },
      {
        // MODULE 9
        title: 'Tocando com o Metrônomo',
        number: 9,
        lessons: [
          'Aula 1 – Exercícios de divisão rítmica e aplicando divisões rítmicas como viradas',
          'Aula 2 – Omitindo tempos do metrônomo',
          'Aula 3 – Interpretando o metrônomo de diferentes maneiras',
        ],
      },
    ],
  ],
}

export default content
